import axios from "axios";
import { showToast } from "./Components/Toast.js";
import { cryptoManager, NoWalletError } from "./Crypto.js";

export class TaskMenu {
  constructor({ onComplete }) {
    this.onComplete = onComplete;
  }

  async createElement() {
    this.element = document.createElement("div");
    this.element.classList.add("SwapMenu");
    this.element.classList.add("overlayMenu");
    this.element.innerHTML = `
      <div class="header">
        <div style="width: 30px"></div>
        <div>How to play?</div>
        <button class="cancelButton" id="cancelButton">&times;</button>
      </div>
      <div class="spacer">
      <div class="smallLabel">Get exclusive airdrop and whitelists</div>
      <div class="swapContainer" id="stoneContainer"></div>
    `;

    const tasks = [
      { label: "Like our Post", buttonText: "Like", link: "https://x.com/intent/like?tweet_id=1877341873884508473" },
      { label: "Follow BeraKingdoms X account", buttonText: "Follow", link: "https://x.com/intent/user?screen_name=BeraKingdoms" },
      { label: "Refer Frens", buttonText: "Refer", link: "https://x.com/intent/tweet?text=Join%20Bera%20Kingdoms%20at%20berakingdoms.com" },
    ];

    const stoneContainer = this.element.querySelector("#stoneContainer");

    tasks.forEach(task => {
      const taskSection = document.createElement("div");
      taskSection.classList.add("taskSection");
      taskSection.innerHTML = `
        <label class="smallTaskLabel">${task.label}</label>
        <button class="taskLabel">${task.buttonText}</button>
      `;
      const button = taskSection.querySelector("button");
      button.addEventListener("click", async () => {
        if (task.link) {
          window.open(task.link, "_blank");
          button.style.backgroundColor = "grey";
        } else {
          try {
            const response = await axios.post("https://api.x.com/oauth/request_token", {
              oauth_callback: "https://www.berakingdoms.com"
            });
            const { oauth_token } = response.data;
            window.open(`https://api.x.com/oauth/authenticate?oauth_token=${oauth_token}`, "_blank");
            button.style.backgroundColor = "grey";

            // After the user authenticates, get the access token and fetch the user's profile
            const accessTokenResponse = await axios.post("https://api.x.com/oauth/access_token", {
              oauth_token,
              oauth_verifier: "verifier_received_from_callback"
            });
            const { oauth_token: accessToken, oauth_token_secret: accessTokenSecret } = accessTokenResponse.data;

            const userProfileResponse = await axios.get("https://api.x.com/1.1/account/verify_credentials.json", {
              headers: {
                Authorization: `OAuth oauth_token="${accessToken}", oauth_token_secret="${accessTokenSecret}"`
              }
            });
            const username = userProfileResponse.data.screen_name;
            console.log("Username:", username);
          } catch (error) {
            console.error("Error during OAuth request:", error);
            showToast("Failed to authenticate with Twitter.");
          }
        }
      });
      stoneContainer.appendChild(taskSection);
    });

    const cancelButton = this.element.querySelector("#cancelButton");
    cancelButton.addEventListener("click", () => {
      this.close();
    });
  }

  close() {
    this.element.remove();
    this.onComplete();
  }

  init(container) {
    this.createElement();
    container.appendChild(this.element);
  }
}

const taskMenu = new TaskMenu({
  onComplete: () => {
    // Logic to execute after swap menu is closed
    taskMenu.onComplete();
    console.log("Task menu closed");
  },
});