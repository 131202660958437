import { showToast } from "./Components/Toast.js";
import { cryptoManager, NoWalletError } from "./Crypto.js";

export class SwapMenu {
  constructor({ onComplete }) {
    this.onComplete = onComplete;
  }

  getOptions() {
    return ingredients.map((ingredient) => {
      return {
        label: ingredient.name,
        value: ingredient.id,
      };
    });
  }

  async createElement() {
    this.element = document.createElement("div");
    this.element.classList.add("SwapMenu");
    this.element.classList.add("overlayMenu");
    this.element.innerHTML = `
      <div class="header">
        <div style="width: 30px"></div>
        <div>Magic Swap Stone</div>
        <button class="cancelButton" id="cancelButton">&times;</button>
      </div>
      <div class="spacer">
      <div class="smallLabel">Swapping test tokens only</div>
      <div class="swapContainer" id="stoneContainer">
        <div class="swapSection">
          <label class="smallLabel" for="sourceIngredient">Swap:</label>
          <div class="swapInputContainer">
            <input class="smallLabel" type="number" id="sourceAmount" min="0" value="0">
            <select class="smallLabel" id="sourceIngredient">
              ${this.getDropdownOptions()}
            </select>
          </div>
          </input>
        </div>
        <div class="arrowContainer">
          <div class="arrow"></div>
        </div>
        <div class="forSection">
          <label class="smallLabel" for="targetIngredient">For:</label>
          <div class="swapInputContainer">
            <input class="smallLabel" type="number" id="destinationAmount" min="0" value="0">
            <select class="smallLabel" id="targetIngredient" default="BERA>
              ${this.getDropdownOptions()}
            </select>
          </div>
        </div>
      </div>
      <div id="outputResult"></div>
    <button class="smallLabel" id="craftButton">Swap</button>
    `;

    // Find the sourceAmount input element
    const sourceAmountInput = this.element.querySelector("#sourceAmount");

    const cancelButton = this.element.querySelector("#cancelButton");

    cancelButton.addEventListener("click", () => {
      this.close();
    });
    
    // Find the destinationAmount input element
    const destinationAmountInput =
      this.element.querySelector("#destinationAmount");

    // Add an input event listener to dynamically update the destinationAmount
    sourceAmountInput.addEventListener("input", () => {
      const sourceAmountValue = parseFloat(sourceAmountInput.value);

      this.showLoadingIndicator("Calculating...");

      // Simulate an API call delay using setTimeout
      setTimeout(() => {
        // Calculate and update the destinationAmount based on the sourceAmount
        const newDestinationAmount = sourceAmountValue * 846;
        destinationAmountInput.value = newDestinationAmount.toFixed(2); // Display decimals with two digits
        this.hideLoadingIndicator();
      }, 500); // Simulated delay of 1 second (adjust as needed)
    });

    // Find the sourceIngredient select element
    const sourceIngredientSelect =
      this.element.querySelector("#sourceIngredient");

    // Add a class to the sourceIngredient select element for styling
    // sourceIngredientSelect.classList.add("noBorder");


    const handleSuccess = async () => {
      await this.hideLoadingIndicator();
      // console.log("SwapMenu.innerHTML: ", SwapMenu.innerHTML);
      this.element.innerHTML = `
      <div>Magic Stone</div>
        <div class="swapContainer" id="stoneContainer">
        </div>
          <div class="mediumLabel">
            You have swapped ${destinationAmountInput.value} BERA
          </div>
      <button class="smallLabel" id="closeButton">Close</button>
      `;
      const closeButton = this.element.querySelector("#closeButton");
      closeButton.addEventListener("click", async () => {
        this.close();
      });    
    }    

    // Add event listener to the Craft button
    const craftButton = this.element.querySelector("#craftButton");
    craftButton.addEventListener("click", async () => {
      if (sourceIngredient) {
        // Close the menu after processing
        
        this.showLoadingIndicator("Swapping...", true);
                    
        try {
          const result = await cryptoManager.swap(1)
          console.log("Contract method result:", result);

          await handleSuccess();
        } catch (error) {
          if (error instanceof NoWalletError) {
            showToast("You need Metamask to play! Please install it to play!");
          } else {
            console.error("Error calling contract method:", error);
          }
          this.showLoadingIndicator("Failed", false);
        }
        
      } else {
        console.error("Invalid source ingredient");
      }
    });
  }

  showLoadingIndicator(text, disabled) {
    const craftButton = this.element.querySelector("#craftButton");
    craftButton.innerText = text; // Change the button text
    craftButton.disabled = disabled; // Disable the button during loading
  }

  // Add a method to hide the loading indicator
  async hideLoadingIndicator() {
    const craftButton = this.element.querySelector("#craftButton");
    craftButton.disabled = false; // Enable the button
    return new Promise((resolve) => {
      // Resolve the promise after the loading is complete
      setTimeout(() => {
        resolve();
        craftButton.innerText = "Swap"; // Restore the original button text
      }, 2000); // Simulated delay of 2 seconds (adjust as needed)
    });
  }

  getDropdownOptions() {
    return this.getOptions()
      .map(
        (option) => `<option value="${option.value}">${option.label}</option>`
      )
      .join("");
  }

  calculateOutput(sourceIngredient, sourceAmount) {
    // Modify this function based on your swapping logic
    return sourceAmount; // Example: Output is the same as the input
  }

  close() {
    this.element.remove();
    this.onComplete();
  }

  init(container) {
    this.createElement();
    container.appendChild(this.element);
  }
}

// Example usage:
const ingredients = [
  { id: 1, name: "BERA" },
  { id: 2, name: "TEST" }, 
];

const swapMenu = new SwapMenu({
  onComplete: () => {
    // Logic to execute after swap menu is closed
    swapMenu.onComplete();
    console.log("Swap menu closed");
  },
});

// Initialize the swap menu in a container
// const container = document.getElementById("container"); // Replace with your container element
// swapMenu.init(container);
