import './styles/main.css';
import { getBlockHeight, cryptoManager } from "./Crypto.js";
import { TitleScreen } from "./TitleScreen.js";
import { initializeApp } from "firebase/app";
import { collection, addDoc, getFirestore } from "firebase/firestore"; 

getBlockHeight()
  .then((blockNumber) => {
    const glowCircleElement =
      document.querySelector(".block-height-text");
    glowCircleElement.textContent = blockNumber;
  })
  .catch((error) => {
    console.error("Error fetching block height:", error);
  });

window.onload = function () {
  document
    .querySelector(".game-canvas")
    .addEventListener("click", function () {
      var audio = document.getElementById("myAudio");
      audio.volume = 0.1; // 50% volume
      audio.paused ? audio.play() : audio.pause();
    });
};

const firebaseConfig = {
  apiKey: "AIzaSyDGJj4B6JKYh-3Iu2eTTUOJVSh8xp8b2TE",
  authDomain: "berakingdoms.firebaseapp.com",
  projectId: "berakingdoms",
  storageBucket: "berakingdoms.firebasestorage.app",
  messagingSenderId: "1055603947559",
  appId: "1:1055603947559:web:64de79c57d4af35b49f602",
  measurementId: "G-ZQ0SLN4BQW"
};

initializeApp(firebaseConfig);

const container = document.querySelector(".game-container");
const titleScreen = new TitleScreen();

await titleScreen.init(container);
cryptoManager.setupAccountChangeListener(() => window.location.reload());